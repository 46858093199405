import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {
  // AllDeals,
  PageTitle,
} from "@components"

const Offerings = () => (
  <Layout>
    <Seo title="Offerings" />
    <PageTitle title="All deals" />
    {/* <AllDeals /> */}
  </Layout>
)

export default Offerings
